import { TemplateApiService } from '@api/template/template.api.service';
import { Observable } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { ChargingStationTemplateDataResult } from '../../types/DataResult';
import { ChargingStationTemplate } from '../../types/ChargingStationTemplate';

@Injectable()
export class TemplateRepositoryService {
  private readonly templateApi = inject(TemplateApiService);

  public getChargerTemplates(): Observable<ChargingStationTemplateDataResult> {
    return this.templateApi.getChargerTemplates();
  }

  public getChargerTemplate(templateId: string): Observable<ChargingStationTemplate> {
    return this.templateApi.getChargerTemplate(templateId);
  }
}
