import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { buildUrl } from '@utils/url/url.util';
import { ChargingStationTemplateDataResult } from '../../types/DataResult';
import { RESTServerRoute } from '../../types/Server';
import { ChargingStationTemplate } from '../../types/ChargingStationTemplate';

@Injectable()
export class TemplateApiService {
  private httpClient = inject(HttpClient);

  public getChargerTemplates(): Observable<ChargingStationTemplateDataResult> {
    return this.httpClient.get<ChargingStationTemplateDataResult>(RESTServerRoute.REST_CHARGING_STATION_TEMPLATES);
  }

  public getChargerTemplate(templateId: string): Observable<ChargingStationTemplate> {
    return this.httpClient.get<ChargingStationTemplate>(
      buildUrl(RESTServerRoute.REST_CHARGING_STATION_TEMPLATE, { id: templateId }),
    );
  }
}
